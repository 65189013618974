// * GOOD FOR PRODUCTION , EMAIL SENDER * //

const { API_KEY } = require('../config');
const DOMAIN = 'nagi.se';

// Initialize Mailgun client
const mailgun = require('mailgun-js')({
	apiKey: API_KEY,
	domain: DOMAIN,
	host: 'api.eu.mailgun.net',
});

// Add subscriber to email list
function addSubscriberToList(email) {
	const data = {
		subscribed: true,
		address: email,
	};
	mailgun
		.lists('nagi-list@nagi.se')
		.members()
		.create(data, function (error, body) {
			console.log(body);
		});
}

// Check if user is already on the mailing list
function isUserOnList(email) {
	return new Promise((resolve, reject) => {
		mailgun
			.lists('nagi-list@nagi.se')
			.members(email)
			.info((error, body) => {
				if (error) {
					if (error.statusCode === 404) {
						resolve(false); // User is not on the list
					} else {
						reject(error); // An error occurred
					}
				} else {
					resolve(true); // User is on the list
				}
			});
	});
}

function isValidEmail(email) {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}

function handleNewsletter(formId, btnId) {
	const form = document.getElementById(formId);
	const btn = document.getElementById(btnId);

	form.addEventListener('submit', async function (e) {
		e.preventDefault(); // Prevent the default form submission behavior

		// Retrieve the email input value
		const emailInput = document.querySelector(`#${formId} input[type="text"]`);
		const userEmail = emailInput.value;

		// Validate email input
		if (!isValidEmail(userEmail)) {
			alert('Please enter a valid email address 😫🤘');
			return;
		}

		try {
			// Check if user is already on the mailing list
			const isOnList = await isUserOnList(userEmail);

			if (isOnList) {
				btn.innerHTML = 'Already subscribed!';
				btn.classList.add('active'); // Add 'active' class to 'btn'
				return;
			}

			// Add user's email to email list
			addSubscriberToList(userEmail);

			// Prepare the email message data
			const messageData = {
				from: 'Newsletter Nagi<fred@nagi.se>',
				to: userEmail,
				subject:
					'Welcome to the Nagi Community: Your Journey to Tranquility Begins!',
				html: `...`, // Your HTML content here
			};

			// Sending email using Mailgun
			mailgun.messages().send(messageData, (error, body) => {
				if (error) {
					console.error('Error:', error);
					btn.innerHTML = 'Error!';
					btn.classList.add('active'); // Add 'active' class to 'btn'
				} else {
					console.log('Email sent successfully:', body);
					btn.innerHTML = 'Success!';
					btn.classList.add('active'); // Add 'active' class to 'btn'
				}
			});
		} catch (error) {
			console.error('An error occurred:', error);
			btn.innerHTML = 'Error!';
		}
	});
}

// Call the function for each newsletter form
handleNewsletter('newsletterForm1', 'submit-btn1');
handleNewsletter('newsletterForm2', 'submit-btn2');
handleNewsletter('newsletterForm3', 'submit-btn3');
